import { Container } from '~/components/container';
import { HStack, VStack } from '~/components/stack';
import { env } from '~/config/env';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';

export function LandingFail() {
  return (
    <div className="bg-card py-16 lg:py-0">
      <Container>
        <HStack className="items-center justify-center">
          <VStack className="gap-6 lg:max-w-[514px]">
            <h2 className="text-2xl lg:text-4xl font-bold">
              Make sure <span className="text-theme">you will succeed!</span>
            </h2>
            <p className="leading-relaxed">
              The most reliable way to ensure success in your exams is to study,
              but not all study methods are equally effective or realistic.
              MEDizzy {env.VITE_APP === 'ems' ? 'NREMT' : 'USMLE'} Mastery
              provides an efficient and practical way to retain the necessary
              knowledge to pass on your first attempt. Our unique approach
              combines practice questions, detailed explanations, and study tips
              to help you understand and remember complex medical concepts. So,
              if you're looking for a dependable study companion to guide you
              through your {env.VITE_APP === 'ems' ? 'NREMT' : 'USMLE'}®
              journey, MEDizzy {env.VITE_APP === 'ems' ? 'NREMT' : 'USMLE'}{' '}
              Mastery is your best bet for passing with flying colors.
            </p>
            <LandingCTAButton />
          </VStack>
          <img
            loading="lazy"
            src="/img/landing/results.png"
            alt="App results"
            className="hidden lg:block"
          />
        </HStack>
      </Container>
    </div>
  );
}

import { cn } from '~/utils/cn';

interface ThemeAwareImageProps {
  dark: string;
  light: string;
  alt: string;
  className?: string;
}

export function ThemeAwareImage({
  dark,
  light,
  alt,
  className,
}: ThemeAwareImageProps) {
  return (
    <>
      <img
        src={light}
        loading="lazy"
        alt={alt}
        className={cn('dark:hidden', className)}
      />
      <img
        src={dark}
        loading="lazy"
        alt={alt}
        className={cn('hidden dark:block', className)}
      />
    </>
  );
}

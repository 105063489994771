import type { PropsWithChildren } from 'react';
import { Container } from '~/components/container';
import { HStack, VStack } from '~/components/stack';
import { env } from '~/config/env';
import { LandingRatingStars } from '~/routes/_index/landing-rating-stars';
import { ROUTES } from '~/utils/routes';

export function LandingReviews() {
  return (
    <div
      id={ROUTES.LANDING.REVIEWS.replace('/#', '')}
      className="bg-card py-16 lg:py-32"
    >
      <Container>
        <VStack className="gap-16">
          <VStack className="items-center gap-4">
            <h2 className="text-2xl lg:text-4xl font-bold">
              Learn what others say about their experiences
            </h2>
          </VStack>
          <div className="w-full grid gap-16 grild-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {(env.VITE_APP === 'ems' ? REVIEWS_EMS : REVIEWS_USMLE).map(
              (review) => (
                <Review
                  key={review.review}
                  avatarSrc={`/img/landing/avatars/${review.user.toLowerCase()}.png`}
                  userName={review.user}
                  userUniversity={review.university}
                >
                  {review.review}
                </Review>
              ),
            )}
          </div>
        </VStack>
      </Container>
    </div>
  );
}

interface ReviewProps {
  avatarSrc: string;
  userName: string;
  userUniversity: string;
}

function Review({
  avatarSrc,
  userUniversity,
  userName,
  children,
}: PropsWithChildren<ReviewProps>) {
  return (
    <VStack className="items-center gap-8">
      <LandingRatingStars />
      <p className="text-center leading-relaxed text-lg">{children}</p>
      <HStack className="items-center gap-4">
        <img
          loading="lazy"
          src={avatarSrc}
          alt={userName}
          className="w-14 h-14 rounded-full"
        />
        <VStack>
          <span className="text-xl font-semibold">{userName}</span>
          <span>{userUniversity}</span>
        </VStack>
      </HStack>
    </VStack>
  );
}

const REVIEWS_USMLE = [
  {
    user: 'Olivia',
    university: 'Ohio State University, USA',
    review:
      'This app transformed my study sessions. The realistic practice questions and comprehensive explanations made all the difference. I could see exactly where I needed to focus and improve. Highly recommend it for USMLE prep!',
  },
  {
    user: 'Hassan',
    university: 'Wake Forest University, USA',
    review:
      'The ultimate study companion for USMLE! The realistic questions and thorough explanations provide a solid understanding of essential topics. The instant feedback is invaluable in tracking progress and areas for improvement. Highly recommended!',
  },
  {
    user: 'Benjamin',
    university: 'University of Pittsburgh, USA',
    review:
      'Fantastic tool for exam preparation! The questions are spot-on and the detailed feedback helps me understand my mistakes. This app really boosted my confidence and readiness for the USMLE exams!',
  },
];

const REVIEWS_EMS = [
  {
    user: 'Josh',
    university: 'University of Colorado, USA',
    review:
      'It has been a game-changer for my exam preparation. The practice questions are incredibly realistic and cover all the essential topics. The instant feedback and detailed explanations help me understand where I need to improve. Highly recommend it to anyone preparing for their Paramedics exams!',
  },
  {
    user: 'Emily',
    university: 'Austin Community College, USA',
    review:
      'This app is a must-have for anyone serious about passing their Paramedic certification. The variety of practice exams and quizzes keeps me engaged and motivated. I especially love the bonus sections on ECG interpretation and medication calculations. It’s like having a personal tutor in my pocket. Worth every penny!',
  },
  {
    user: 'Michael',
    university: 'Boston EMS Academy',
    review:
      'The app has exceeded my expectations. The questions are challenging and closely mimic the actual exam format. The app’s user-friendly interface and comprehensive review sections make studying efficient and effective. I’ve gained so much confidence in my knowledge and skills thanks to this app. Five stars!',
  },
] as const;

import { type VariantProps, cva } from 'class-variance-authority';
import { CheckIcon } from 'lucide-react';
import { Container } from '~/components/container';
import { HStack, VStack } from '~/components/stack';
import { env } from '~/config/env';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';
import { LandingRatingStars } from '~/routes/_index/landing-rating-stars';
import { ROUTES } from '~/utils/routes';

const PRICING_FEATURES = [
  'Unlimited Qbank access',
  'Unlimited number of questions to learn',
  `${env.VITE_APP === 'ems' ? '1200+' : '1500+'} exam style questions`,
  'Exam creator',
  'Detailed explanations',
  'Key information to remember',
  'Pearls',
  'References',
  'Advanced analytics',
  'Daily Goal',
  'Retake incorrect',
  'Notepad',
  'Dark mode',
];

export function LandingPricing() {
  return (
    <VStack>
      <div className="bg-card">
        <Container className="py-16 lg:py-32">
          <VStack className="gap-8 items-center justify-center">
            <VStack>
              <h2 className="text-2xl lg:text-4xl font-bold max-w-[550px] text-center !leading-normal">
                Get access to everything and{' '}
                <span className="text-theme">
                  Crush{' '}
                  {env.VITE_APP === 'ems' ? 'NREMT® Paramedics' : 'USMLE®'}{' '}
                  exam!
                </span>
              </h2>
            </VStack>
            <VStack className="max-w-[600px] text-center items-center gap-4">
              <p className="leading-relaxed">
                It has been a game-changer for my exam preparation. The practice
                questions are incredibly realistic and cover all the essential
                topics.
              </p>
              <HStack className="items-center gap-4">
                <LandingRatingStars />
                <img
                  loading="lazy"
                  src="/img/landing/avatars/jacob.png"
                  alt="Jacob"
                  className="w-10 h-10"
                />
                <span className="font-semibold">Jacob</span>
              </HStack>
            </VStack>
          </VStack>
        </Container>
      </div>
      <Container
        id={ROUTES.LANDING.PRICING.replace('/#', '')}
        className="py-16 lg:py-32"
      >
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <PricingCard
            period="Free Access"
            price={0}
            items={['Unlimited Qbank access', '50 questions to learn']}
          />
          <PricingCard
            period="Monthly"
            price={env.VITE_APP === 'ems' ? 14.99 : 24.99}
            items={PRICING_FEATURES}
          />
          <PricingCard
            period="Yearly"
            price={env.VITE_APP === 'ems' ? 9.99 : 19.99}
            items={PRICING_FEATURES}
            variant="best-deal"
          />
        </div>
      </Container>
      <div className="bg-navbar py-16 lg:py-32">
        <Container>
          <VStack className="justify-center items-center gap-8">
            <h2 className="text-2xl lg:text-4xl font-bold text-white max-w-[480px] text-center !leading-normal">
              <span className="opacity-80">Ready to crush your</span>{' '}
              {env.VITE_APP === 'ems' ? 'NREMT® Paramedic' : 'USMLE® Step 1'}{' '}
              exam?
            </h2>
            <LandingCTAButton className="bg-white text-black hover:bg-white/80" />
          </VStack>
        </Container>
      </div>
    </VStack>
  );
}

const card = cva('bg-card p-6 rounded-xl border-2 gap-6 relative', {
  variants: {
    variant: {
      default: 'border-card',
      'best-deal': 'border-theme',
    },
  },
});

const button = cva('w-full', {
  variants: {
    variant: {
      default: 'bg-muted text-foreground hover:bg-muted/50',
      'best-deal': 'bg-navbar',
    },
  },
});

interface PricingCardProps extends VariantProps<typeof card> {
  period: string;
  price: number;
  items: string[];
}

function PricingCard({
  variant = 'default',
  period,
  items,
  price,
}: PricingCardProps) {
  return (
    <VStack className={card({ variant })}>
      <span className="text-lg font-semibold">{period}</span>
      <VStack>
        <span className="text-4xl font-semibold">
          ${price}
          {price !== 0 && <span className="text-xl">/month</span>}
        </span>
        {price !== 0 && (
          <span className="text-muted-foreground pt-1">
            (billed {period.toLowerCase()})
          </span>
        )}
      </VStack>
      <VStack className="gap-3 flex-1">
        {items.map((item) => (
          <HStack key={item} className="items-center gap-2">
            <CheckIcon size={16} />
            <span>{item}</span>
          </HStack>
        ))}
      </VStack>
      <LandingCTAButton className={button({ variant })} />
      {variant === 'best-deal' && (
        <span className="bg-theme/20 text-theme px-3 py-1.5 rounded-lg absolute top-6 right-6 font-semibold">
          Best Deal
        </span>
      )}
    </VStack>
  );
}

import type { PropsWithChildren } from 'react';
import { Container } from '~/components/container';
import { Stack, VStack } from '~/components/stack';
import { env } from '~/config/env';
import { ThemeAwareImage } from '~/features/theme/theme-aware-image';
import { cn } from '~/utils/cn';

const items = [
  {
    img: 'mock-exams',
    title: 'Mock Exams',
    desc: 'Simulate the real test experience with comprehensive practice exams.',
  },
  {
    img: 'mastery',
    title: 'Track Your Mastery',
    desc: 'Track your progress and identify areas for improvement with performance analytics.',
  },
  {
    img: 'countdown',
    title: 'Countdown',
    desc: 'Set your exam date and stay motivated with a personalized countdown timer.',
  },
  {
    img: 'daily-goal',
    title: 'Daily Goal',
    desc: 'Keep your study routine on track by setting and achieving daily question targets.',
  },
  {
    img: 'notepad',
    title: 'Notepad',
    desc: 'Organize your thoughts, and keep track of essential information',
  },
  {
    img: 'dark-mode',
    title: 'Dark Mode',
    desc: 'Comfortable interface that’s easy on your eyes, day or night.',
  },
  {
    img: 'pearls',
    title: 'Pearls',
    desc: 'Key takeaways and expert tips to sharpen your knowledge and focus.',
  },
  {
    img: 'key-information',
    title: 'Key Information',
    desc: 'Critical insights to stay on top of your learning and streamline your study sessions.',
  },
] as const;

export function LandingResults() {
  return (
    <div className="bg-card py-16 lg:py-32">
      <Container className="grid gap-8 lg:gap-4 grid-cols-1 lg:grid-cols-12">
        <VStack className="gap-6 lg:col-span-3">
          <h2 className="text-2xl lg:text-4xl font-bold">
            Built for the best result.
          </h2>
          <p className="text-lg leading-relaxed">
            MEDizzy {env.VITE_APP === 'ems' ? 'EMS' : 'USMLE'} Mastery is the
            efficient and practical way to retain the knowledge you need to pass
            the your {env.VITE_APP === 'ems' ? 'NREMT' : 'USMLE'}® exam first
            time.
          </p>
        </VStack>
        <div
          className={cn(
            'grid gap-8 grid-cols-1 md:grid-cols-2',
            'lg:col-span-7 lg:col-start-6',
          )}
        >
          {items.map((item) => (
            <ResultsItem key={item.title} img={item.img} title={item.title}>
              {item.desc}
            </ResultsItem>
          ))}
        </div>
      </Container>
    </div>
  );
}

interface ResultsItemProps {
  img: string;
  title: string;
}

function ResultsItem({
  img,
  title,
  children,
}: PropsWithChildren<ResultsItemProps>) {
  return (
    <Stack className="flex-col gap-4 md:gap-8 md:flex-row md:items-start">
      <ThemeAwareImage
        dark={`/img/landing/results/${img}-dark.png`}
        light={`/img/landing/results/${img}-light.png`}
        alt={title}
        className="max-w-[44px]"
      />
      <VStack className="gap-2">
        <h3 className="text-xl font-semibold">{title}</h3>
        <p className="leading-relaxed">{children}</p>
      </VStack>
    </Stack>
  );
}

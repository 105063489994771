import { Link } from '@remix-run/react';
import { Button, type ButtonProps } from '~/components/button';
import { ROUTES } from '~/utils/routes';

export function LandingCTAButton(props?: ButtonProps) {
  return (
    <Link to={ROUTES.DECKS.BROWSE}>
      <Button variant="theme" size="lg" {...props}>
        Try for free!
      </Button>
    </Link>
  );
}

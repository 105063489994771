import { Container } from '~/components/container';
import { Stack, VStack } from '~/components/stack';
import { env } from '~/config/env';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';

export function LandingLearn() {
  return (
    <div className="bg-card">
      <Container className="flex justify-center">
        <Stack className="items-center gap-8 flex-col lg:flex-row lg:gap-16">
          <VStack className="gap-8 lg:max-w-[480px]">
            <h2 className="text-2xl lg:text-4xl font-bold max-w-[300px]">
              Learn in a way{' '}
              <span className="text-theme">that really works!</span>
            </h2>
            <p className="text-lg leading-relaxed">
              Preparing for your{' '}
              {env.VITE_APP === 'ems' ? 'NREMT® Paramedic' : 'USMLE®'} exams
              might seem hard and you may think it requires a lot to get
              started. That’s why we created MEDizzy{' '}
              {env.VITE_APP === 'ems' ? 'EMS' : 'USMLE'} Mastery - a powerful
              learning app that helps you study effectively so{' '}
              <b>
                you will never feel overwhelmed on your{' '}
                {env.VITE_APP === 'ems' ? 'NREMT®' : 'USMLE®'} exams
              </b>
              .
            </p>
            <LandingCTAButton />
          </VStack>
          <img
            loading="lazy"
            src={`/img/landing/learn-${env.VITE_APP}.png`}
            alt="App preview"
          />
        </Stack>
      </Container>
    </div>
  );
}

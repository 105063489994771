import { StarIcon } from 'lucide-react';
import { HStack } from '~/components/stack';

export function LandingRatingStars() {
  return (
    <HStack className="gap-0.5">
      {Array.from({ length: 5 }).map((_, idx) => (
        <StarIcon key={idx} className="stroke-amber-400 fill-amber-400" />
      ))}
    </HStack>
  );
}

import { Container } from '~/components/container';
import { VStack } from '~/components/stack';
import { env } from '~/config/env';
import { DeckCard } from '~/features/learning/deck/deck-card';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';

export function LandingTest() {
  return (
    <div className="relative">
      <svg
        width="413"
        height="817"
        viewBox="0 0 413 817"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-0 top-0 z-0 hidden lg:block"
      >
        <title>Decorative path</title>
        <path
          d="M370.5 26C397 86 410.1 222.5 250.5 288.5C51 371 151.5 564 95 712.5C49.8 831.3 -33.8333 788.667 -70 752.5"
          stroke="#D8F3FF"
          strokeWidth="51"
          strokeLinecap="round"
        />
      </svg>
      <Container className="relative z-10">
        <VStack className="max-w-[780px] mx-auto py-20 items-center gap-8">
          <h2 className="text-2xl lg:text-4xl font-bold text-center leading-relaxed">
            Test yourself on{' '}
            <span className="text-theme">
              {env.VITE_APP === 'ems' ? 117 : 100} topics
            </span>
            , in {env.VITE_APP === 'ems' ? 8 : 16} major categories:
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            {(env.VITE_APP === 'ems' ? EMS_CATEGORIES : USMLE_CATEGORIES).map(
              (category) => (
                <DeckCard
                  key={category.id}
                  id={category.id}
                  title={category.name}
                  progress={{
                    total: 0,
                    incorrect: 0,
                    correct: 0,
                  }}
                  questions={category.questions}
                  decks={category.decks}
                />
              ),
            )}
          </div>
          <LandingCTAButton />
        </VStack>
      </Container>
      <svg
        width="141"
        height="866"
        viewBox="0 0 141 866"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-0 top-0 hidden lg:block"
      >
        <title>Decorative</title>
        <path
          d="M140.532 26.0001C84.6378 55.1801 -13.1414 165.266 42.8996 372.167C112.951 630.795 394.563 544.942 330.986 839.897"
          stroke="#D8F3FF"
          strokeWidth="51"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

const USMLE_CATEGORIES = [
  {
    id: 1015,
    name: 'Biochemistry',
    questions: 101,
    decks: 6,
  },
  {
    id: 1011,
    name: 'Cardiovascular',
    questions: 100,
    decks: 6,
  },
  {
    id: 1009,
    name: 'Endocrinology',
    questions: 100,
    decks: 5,
  },
  {
    id: 1010,
    name: 'Gastrointestinal',
    questions: 199,
    decks: 6,
  },
  {
    id: 1002,
    name: 'Hematology & Oncology',
    questions: 100,
    decks: 5,
  },
  {
    id: 1013,
    name: 'Immunology',
    questions: 50,
    decks: 19,
  },
  {
    id: 1006,
    name: 'Microbiology',
    questions: 200,
    decks: 7,
  },
  {
    id: 1007,
    name: 'Musculoskeletal, Skin & Connective Tissue',
    questions: 100,
    decks: 4,
  },
  {
    id: 1012,
    name: 'Neurology',
    questions: 100,
    decks: 6,
  },
  {
    id: 1014,
    name: 'Pathology',
    questions: 100,
    decks: 4,
  },
] as const;

const EMS_CATEGORIES = [
  {
    id: 24131121,
    name: 'Airway Management',
    questions: 99,
    decks: 11,
  },
  {
    id: 24131628,
    name: 'Basic Life Support',
    questions: 98,
    decks: 10,
  },
  {
    id: 24200020,
    name: 'Clinical Judgement',
    questions: 150,
    decks: 11,
  },
  {
    id: 27,
    name: 'ECG',
    questions: 100,
    decks: 7,
  },
  {
    id: 24132129,
    name: 'Emergencies',
    questions: 100,
    decks: 5,
  },
  {
    id: 24200000,
    name: 'EMS Operations',
    questions: 150,
    decks: 11,
  },
  {
    id: 24133884,
    name: 'Infectious Diseases',
    questions: 200,
    decks: 7,
  },
  {
    id: 24200010,
    name: 'Obstetrics & Gynaecology',
    questions: 145,
    decks: 12,
  },
  {
    id: 24134401,
    name: 'Resuscitation',
    questions: 100,
    decks: 6,
  },
  {
    id: 24134935,
    name: 'Toxicology',
    questions: 200,
    decks: 7,
  },
  {
    id: 24135194,
    name: 'Trauma',
    questions: 100,
    decks: 4,
  },
] as const;
